import Vue from 'vue'
import App from './App.vue'
import router from './router'
import titleMixin from '../util/title'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false
Vue.mixin(titleMixin)
Vue.use(VueAxios, axios)

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
