<template>
  <div id="app">
    <login-header v-if="this.$route.path === '/'" />
    <default-header v-else />
    <router-view />
    <default-footer />
  </div>
</template>

<script>
import DefaultFooter from './components/DefaultFooter.vue'
import LoginHeader from './components/LoginHeader.vue'
import DefaultHeader from './components/DefaultHeader.vue'
export default {
  name: 'App',
  components: { LoginHeader, DefaultHeader, DefaultFooter }
}
</script>


