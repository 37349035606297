<template>
  <main class="py-4">
    <div class="container">
      <h1>ログイン情報の追加</h1>

      <form action="../xxx/" method="post" class="needs-validation" novalidate>
        <input type="hidden" name="_method" value="POST" />
        <input
          type="hidden"
          name="_token"
          value="cC6DC03OpHnbbfLAQhqo5KjB2WAI0zWs0tf0xRHk"
        />
        <div class="form-group row required">
          <label for="loginuser_id" class="col-sm-2 col-form-label"
            >ユーザID</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="loginuser_id"
              name="loginuser_id"
              v-model="form.loginid"
              required
              minlength="6"
              maxlength="10"
              pattern="[a-zA-Z\d_]{6,10}"
              autoforcus
            />
            <div class="invalid-feedback">
              (!) ユーザIDを入力して下さい（半角英大文字・半角英小文字・半角数字・アンダーバー「 _ 」、6文字以上10文字以下）
            </div>
          </div>
        </div>
        <div class="form-group row required">
          <label for="loginuser_name" class="col-sm-2 col-form-label"
            >氏名</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="loginuser_name"
              name="loginuser_name"
              v-model="form.usernm"
              required
              pattern="^[^ -~｡-ﾟ]+$" maxlength="50"
            />
            <div class="invalid-feedback">
              (!) 氏名を入力して下さい（全角文字のみ、50文字以下）
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="loginuser_mailaddress" class="col-sm-2 col-form-label"
            >メールアドレス</label
          >
          <div class="col-sm-10">
            <input
              type="email"
              class="form-control"
              id="loginuser_mailaddress"
              name="loginuser_mailaddress"
              v-model="form.usermail"
              aria-describedby="emailHelp"
              pattern="[\w\-._]+@[\w\-._]+\.[A-Za-z]+"
              maxlength="256"
            />
            <div class="invalid-feedback">
              (!) 正しいメールアドレスの形式で入力して下さい
            </div>
          </div>
        </div>
        <div class="form-group row required">
          <label for="loginuser_password" class="col-sm-2 col-form-label"
            >パスワード</label
          >
          <div class="col-sm-10">
            <input
              type="password"
              class="form-control"
              id="loginuser_password"
              name="loginuser_password"
              v-model="form.loginpassword"
              required
              pattern="(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{6,30}"
            />
            <div class="invalid-feedback">
              (!) 半角英大文字・半角英小文字・半角数字いずれも必ず含む6文字以上30文字以下で入力してください
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="offset-sm-2 col-sm-10">
            <button type="button" class="btn btn-primary" @click="modalCreate()">登録</button>
          </div>
        </div>
      </form>
    </div>
    
    <!-- Modal：登録 -->
    <div class="modal fade" id="saveModal" tabindex="-1" ref="modal_Insert">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="saveModalLabel">登録確認</h5>
            <button type="button" class="close" @click="modalClose()">✕</button>
          </div>
          <div class="modal-body">登録します。よろしいですか？</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalClose()">cancel</button>
            <button type="submit" id="buttonOK" class="btn btn-primary" @click="submitForm()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
  title: "ログイン情報登録画面",
  name: "loginuser",
  components: {},
  data(){
    return {
      modeModal: null,
      form: {
          loginid: '',
          loginpassword: '',
          usernm: '',
          usermail: ''
      },
      items:   [],                                  // webAPIから取得したデータ
      results: []                                   // itemsから抽出したデータ
    }
  },
  created(){
    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods:{
    modalCreate(){
      let booleanValidity = true;
      var forms = document.querySelectorAll('.needs-validation');
      Array.prototype.slice.call(forms).forEach(function (formsControl) {
        if (!formsControl.checkValidity()) {
          booleanValidity = false;
        }
        formsControl.classList.add('was-validated');
      });
      if (booleanValidity){
        this.modeModal = new Modal(document.getElementById('saveModal'), {keyboard: true})
        this.modeModal.show();
      }
    },
    modalClose: function () {
      let elements = document.getElementsByTagName("div");
      Array.prototype.forEach.call(elements, function (element) {
          element.classList.remove("modal-open");
      });
      this.$delete(this.modeModal, 'modal-backdrop');
      this.modeModal.hide();
    },
    submitForm(){
      const vue = this;   // Important
      // 追加処理
      vue.submitNew();
      vue.modalClose()
    },
    /* 追加処理 */
    submitNew(){
      //const url = 'http://localhost:8080/api/LoginUserInsert'
      const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/LoginUserInsert'
      const vue = this;   // Important
      const option = {responseType: "blob"};
      this.axios.post(url, this.form, option)
        .then((res) => {
          res.data.text().then(str=>{vue.init(str);});
        if (res.status == 500) {
// ★★★：Internal Server Error
          // response.status == 500
          // Internal Server Error
          console.log(res);
          alert('Internal Server Error');
        } else {
          // 画面遷移「ログイン情報一覧画面」
          this.$router.push({name: 'loginlist'})
        }
      })
      .catch((e) => {
// ★★★：エラーメッセージ
        console.log(e);
        alert(e.response.status + ':' + e.message);
      });
    },
    init(str){
      this.items = JSON.parse(str).data;      // JSONオブジェクトに変換
      this.reset();
    },
    reset(){
      this.results = this.items;              // resultsにコピー
    }
  }
};
</script>