<template>
  <main class="py-4">
    <div class="container" v-for="form in results" v-bind:key="form.loginid">
      <h1 v-once>編集: {{ form.usernm }}</h1>
      
      <form action="../xxx/0001" method="post" name="frmEdit" class="needs-validation" novalidate>
        <input type="hidden" name="_method" value="POST" />
        <input
          type="hidden"
          name="_token"
          value="cC6DC03OpHnbbfLAQhqo5KjB2WAI0zWs0tf0xRHk"
        />
        
        <div class="form-group">
          <button type="button" id="btnDelete" class="btn btn-danger" @click="modalCreate('btnDelete')">削除</button>
        </div>
        <div class="form-group mb-3 row required">
          <label for="loginuser_id" class="col-sm-2 col-form-label"
            >ユーザID</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="loginuser_id"
              name="loginuser_id"
              v-model="form.loginid"
              required
              readonly
            />
          </div>
        </div>
        <div class="form-group row required">
          <label for="loginuser_name" class="col-sm-2 col-form-label"
            >氏名</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="loginuser_name"
              name="loginuser_name"
              v-model="form.usernm"
              required
              pattern="^[^ -~｡-ﾟ]+$"
              maxlength="50"
            />
            <div class="invalid-feedback">(!) 氏名を入力して下さい（全角文字のみ、50文字以下）</div>
          </div>
        </div>
        <div class="form-group row">
          <label for="loginuser_mailaddress" class="col-sm-2 col-form-label"
            >メールアドレス</label
          >
          <div class="col-sm-10">
            <input
              type="email"
              class="form-control"
              id="loginuser_mailaddress"
              name="loginuser_mailaddress"
              v-model="form.usermail"
              pattern="[\w\-._]+@[\w\-._]+\.[A-Za-z]+"
              maxlength="256"
            />
            <div class="invalid-feedback">(!) 正しいメールアドレスの形式で入力して下さい</div>
          </div>
        </div>
        <div class="form-group mb-3 row required">
          <label for="loginuser_password" class="col-sm-2 col-form-label"
            >パスワード</label
          >
          <div class="col-sm-10">
            <input
              type="password"
              class="form-control"
              id="loginuser_password"
              name="loginuser_password"
              v-model="form.loginpassword"
              required
              pattern="(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{6,30}"
            />
            <div class="invalid-feedback">(!) 半角英大文字・半角英小文字・半角数字いずれも必ず含む6文字以上30文字以下で入力してください</div>
          </div>
        </div>

        <div class="form-group row">
          <div class="offset-sm-2 col-sm-10">
            <button type="button" id="btnEdit" class="btn btn-primary" @click="modalCreate('btnEdit')">編集</button>
          </div>
        </div>
      </form>
    </div>
    
    <!-- Modal：編集 -->
    <div class="modal fade" id="updateModal" tabindex="-1" ref="modal_Update">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updateModalLabel">編集確認</h5>
            <button type="button" class="close" @click="modalClose()">✕</button>
          </div>
          <div class="modal-body">編集します。よろしいですか？</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalClose()">cancel</button>
            <button type="submit" class="btn btn-primary" @click="submitForm('btnEdit')">OK</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal：削除 -->
    <div class="modal fade" id="deleteModal" tabindex="-1" ref="modal_Delete">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">削除確認</h5>
            <button type="button" class="close" @click="modalClose()">✕</button>
          </div>
          <div class="modal-body">削除します。よろしいですか？</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalClose()">cancel</button>
            <button type="submit" class="btn btn-primary" @click="submitForm('btnDelete')">OK</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
  title: "ログイン情報登録画面",
  name: "loginuser0001",
  components: {},
  data(){
    return {
      modeModal: null,
      form: {
        usernm_dumy: '',
        loginid: '',
        loginpassword: '',
        usernm: '',
        usermail: ''
      },
      items:   [],                                  // webAPIから取得したデータ
      results: []                                   // itemsから抽出したデータ
    }
  },
  created(){
    var dumyUrl = new URL(window.location.href);
    let params = dumyUrl.searchParams;
    //const url = 'http://localhost:8080/api/LoginUserSelect'
    const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/LoginUserSelect'
    if (params.get('loginid') !== null) {
      const vue = this;   // Important
      const option = {responseType: "blob"};
      this.axios.get(url + "/" + params.get('loginid'), option).then(res=>{
        res.data.text().then(str=>{vue.init(str);});
        if (res.status == 500) {
// ★★★：Internal Server Error
          // response.status == 500
          // Internal Server Error
          console.log(res);
          alert('Internal Server Error');
        }
      }).catch(e=>{
// ★★★：エラーメッセージ
        // response.status == 500
        // Internal Server Error
        console.log(e);
        alert(e.response.status + ':' + e.message);
      });
    }
    
    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods:{
    modalCreate(btnMode){
      if (btnMode == 'btnEdit') {
        let booleanValidity = true;
        var forms = document.querySelectorAll('.needs-validation');
        Array.prototype.slice.call(forms).forEach(function (formsControl) {
          if (!formsControl.checkValidity()) {
            booleanValidity = false;
          }
          formsControl.classList.add('was-validated');
        });
        if (booleanValidity){
          this.modeModal = new Modal(document.getElementById('updateModal'), {keyboard: true})
          this.modeModal.show();
        }
      }
      if (btnMode == 'btnDelete') {
        this.modeModal = new Modal(document.getElementById('deleteModal'), {keyboard: true})
        this.modeModal.show();
      }
    },
    modalClose() {
      let elements = document.getElementsByTagName("div");
      Array.prototype.forEach.call(elements, function (element) {
          element.classList.remove("modal-open");
      });
      this.$delete(this.modeModal, 'modal-backdrop');
      this.modeModal.hide();
    },
    submitForm(btnMode){
      const vue = this;   // Important
      
      if (btnMode=='btnEdit') {
        // 編集処理
        vue.submitEdit()
        vue.modalClose()
      }
      if (btnMode=='btnDelete') {
        // 削除処理
        vue.submitDelete();
        vue.modalClose()
      }
    },
    /* 編集処理 */
    submitEdit(){
      var Parameter_loginid = this.form[0].loginid;
      const Parameter_body = {
        usernm: this.form[0].usernm,
        usermail: this.form[0].usermail,
        loginpassword: this.form[0].loginpassword
      };
      
      //const url = 'http://localhost:8080/api/LoginUserUpdate'
      const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/LoginUserUpdate'
      const vue = this;   // Important
      const option = {responseType: "blob"};
      this.axios.put(url + "/" + Parameter_loginid, Parameter_body, option)
      .then((res) => {
        res.data.text().then(str=>{vue.init(str);});
        if (res.status == 500) {
// ★★★：Internal Server Error
          // response.status == 500
          // Internal Server Error
          console.log(res);
          alert('Internal Server Error');
        } else {
          // 画面遷移「ログイン情報一覧画面」
          this.$router.push({name: 'loginlist'})
        }
      })
      .catch((e) => {
// ★★★：エラーメッセージ
        console.log(e);
        alert(e.response.status + ':' + e.message);
      });
    },
    /* 削除処理 */
    submitDelete(){
      var Parameter_loginid = this.form[0].loginid;
      //const url = 'http://localhost:8080/api/LoginUserDelete'
      const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/LoginUserDelete'
      const vue = this;   // Important
      const option = {responseType: "blob"};
      this.axios.delete(url + "/" + Parameter_loginid, option)
      .then((res) => {
        res.data.text().then(str=>{vue.init(str);});
        if (res.status == 500) {
// ★★★：Internal Server Error
          // response.status == 500
          // Internal Server Error
          console.log(res);
          alert('Internal Server Error');
        } else {
          // 画面遷移「ログイン情報一覧画面」
          this.$router.push({name: 'loginlist'})
        }
      })
      .catch((e) => {
// ★★★：エラーメッセージ
        console.log(e);
        alert(e.response.status + ':' + e.message);
      });
    },
    init(str){
      this.items = JSON.parse(str).data;      // JSONオブジェクトに変換
      this.reset();
    },
    reset(){
      this.results = this.items;              // resultsにコピー
      this.form = this.items;
    }
  }
};
</script>