<template>
  <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm sticky-top">
    <div class="container">
<!--
      <a class="navbar-brand" href="/manager/">
          <img src="https://infront-system.net/manager/images/logo.png" alt="SmartinManager">
      </a>
-->
      <a class="navbar-brand">
        <img
          src="../../public/assets/images/e_others_170.png"
          alt="mock_Certification"
        />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarSupportedContent"
      >
        <!-- Left Side Of Navbar -->
        <ul class="navbar-nav mr-auto">
<!--
          <li class="nav-item">
              <a class="nav-link" href="https://infront-system.net/manager/companies"><h4>企業情報</h4></a>
          </li>
-->
          <li class="nav-item">
            <router-link
              class="nav-link"
              v-bind:class="{
                active:
                  (this.$route.path === '/menulist')
                  | (this.$route.path === '/company')
                  | (this.$route.path === '/company0001')
              }"
              to="/menulist"
              ><h4>認証企業登録</h4></router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              v-bind:class="{
                active: this.$route.path === '/inquiry_company'
              }"
              to="/inquiry_company"
              ><h4>認証依頼件数照会</h4></router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              v-bind:class="{
                active:
                  (this.$route.path === '/loginlist')
                  | (this.$route.path === '/loginuser')
                  | (this.$route.path === '/loginuser0001')
              }"
              to="/loginlist"
              ><h4>ログイン情報登録</h4></router-link
            >
          </li>
        </ul>

        <!-- Right Side Of Navbar -->
        <ul class="navbar-nav ml-auto">
          <!-- Authentication Links -->
          <!--
                      <li class="nav-item dropdown">
                          <a id="navbarDropdown" class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-pre>
                              user0001 <span class="caret"></span>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                              <a class="dropdown-item" href="https://infront-system.net/manager/logout"
                                  onclick="event.preventDefault();
                                      document.getElementById('logout-form').submit();">
                                  ログアウト
                              </a>
                              <form id="logout-form" action="https://infront-system.net/manager/logout" method="POST" style="display: none;">
                              <input type="hidden" name="_token" value="I1vN2yx6P92ZFPOvsngNJFlLHXzMUi7FebGU8vIr">
                              </form>
                          </div>
                      </li>
          -->
          <form>
            <div class="form-group row mb-0 width">
              <div class="col-md-8">
                <button
                  name="logout"
                  type="submit"
                  class="btn text-nowrap btn-outline-secondary"
                  @click="logout"
                >
                  ログアウト
                </button>
              </div>
            </div>
          </form>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    logout: function () {
      // ログアウトAPIが動かないため、画面遷移のみ
      // const url = 'logoutAPIのURL'
      // this.$http
      //   .post(url, function () {
      //     // ログアウト成功時の処理
      //     this.$router.push({
      //       name: 'login'
      //     })
      //   })
      //   .error(function () {
      //     // 失敗時の処理
      //   })
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>