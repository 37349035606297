<template>
  <main class="py-4">
    <div class="container">
      <h1>ログイン情報一覧</h1>
      <div class="mb-2">
        <router-link to="/loginuser" class="btn btn-primary"
          >新規追加</router-link
        >
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm">
          <thead>
            <tr class="table-info">
              <th style="width: 20%">ユーザID</th>
              <th style="width: 40%">氏名</th>
              <th style="width: 40%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in results" v-bind:key="item.loginid">
              <td><router-link :to="`loginuser0001/?loginid=${item.loginid}`">{{ item.loginid }}</router-link></td>
              <td>{{ item.usernm }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  title: "ログイン情報一覧画面",
  name: "loginlist",
  components: {},
  data(){
    return {
      items:   [],                                  // webAPIから取得したデータ
      results: []                                   // itemsから抽出したデータ
    }
  },
  created(){
    //const url = 'http://localhost:8080/api/LoginList'
    const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/LoginList'
    const vue = this;   // Important
    const option = {responseType: "blob"};
    this.axios.get(url, option).then(res=>{
      res.data.text().then(str=>{vue.init(str);});
      if (res.status == 500) {
// ★★★：Internal Server Error
        // response.status == 500
        // Internal Server Error
        console.log(res);
        alert('Internal Server Error');
      }
    }).catch((e) => {
// ★★★：エラーメッセージ
      console.log(e);
      alert(e.response.status + ':' + e.message);
    });
    
    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods:{
    init(str){
      this.items = JSON.parse(str).data;      // JSONオブジェクトに変換
      this.reset();
    },
    reset(){
      this.results = this.items;              // resultsにコピー
    }
  }
};
</script>