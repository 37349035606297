<template>
  <main class="py-4">
    <div class="container">
      <h1>企業情報の追加</h1>
      
      <form action="../xxx/" method="post" class="needs-validation" novalidate>
        <input type="hidden" name="_method" value="POST" />
        <input
          type="hidden"
          name="_token"
          value="cC6DC03OpHnbbfLAQhqo5KjB2WAI0zWs0tf0xRHk"
        />
        <div class="form-group row required">
          <label for="company_code" class="col-sm-2 col-form-label"
            >利用企業コード</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_code"
              name="company_code"
              v-model="form.certcompanycd"
              required
              pattern="(^[1-9][0-9]*$)"
              maxlength="12"
              autoforcus
            />
            <div class="invalid-feedback">
              (!) 利用企業コードを入力して下さい（半角数字、12文字以下）
            </div>
          </div>
        </div>
        <div class="form-group row required">
          <label for="company_name" class="col-sm-2 col-form-label"
            >企業名</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_name"
              name="company_name"
              v-model="form.companynm"
              required
              pattern="^[^ -~｡-ﾟ]+$"
              maxlength="50"
            />
            <div class="invalid-feedback">
              (!) 企業名を入力して下さい（全角文字のみ、50文字以下）
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="company_postcode" class="col-sm-2 col-form-label"
            >住所</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_postcode"
              name="company_postcode"
              v-model="form.zipcd"
              pattern="\d{3}-?\d{4}"
            />
            <div class="invalid-feedback">
              (!) 正しい郵便番号の形式で入力して下さい（半角数字3文字＋半角ハイフン(-)省略可＋半角数字4文字）
            </div>
          </div>
          <label for="company_address" class="col-sm-2 col-form-label"></label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_address"
              name="company_address"
              v-model="form.address"
              pattern="^[^ -~｡-ﾟ]+$"
              maxlength="100"
            />
            <div class="invalid-feedback">
              (!) 有効な住所の形式で入力して下さい（全角文字のみ、100文字以下）
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="company_chief" class="col-sm-2 col-form-label"
            >連絡先責任者</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_chief"
              name="company_chief"
              v-model="form.chief"
              pattern="^[^ -~｡-ﾟ]+$"
              maxlength="25"
            />
            <div class="invalid-feedback">
              (!) 有効な形式で入力して下さい（全角文字のみ、25文字以下）
            </div>
          </div>
          <label for="company_chieftelno" class="col-sm-2 col-form-label"
            >電話番号</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_chieftelno"
              name="company_chieftelno"
              v-model="form.chieftelno"
              pattern="\d{2,4}-?\d{2,4}-?\d{3,4}"
            />
            <div class="invalid-feedback">
              (!) 有効な形式で入力して下さい（数字2～4文字＋ハイフン(-)省略可＋数字2～4文字＋ハイフン(-)省略可＋数字3～4文字）
            </div>
          </div>
          <label for="company_chiefmail" class="col-sm-2 col-form-label"
            >メールアドレス</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_chiefmail"
              name="company_chiefmail"
              v-model="form.chiefmail"
              aria-describedby="emailHelp"
              pattern="[\w\-._]+@[\w\-._]+\.[A-Za-z]+"
              maxlength="256"
            />
            <div class="invalid-feedback">
              (!) 正しいメールアドレスの形式で入力して下さい
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="company_staff" class="col-sm-2 col-form-label"
            >連絡先担当者</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_staff"
              name="company_staff"
              v-model="form.staff"
              pattern="^[^ -~｡-ﾟ]+$"
              maxlength="25"
            />
            <div class="invalid-feedback">
              (!) 有効な形式で入力して下さい（全角文字のみ、25文字以下）
            </div>
          </div>
          <label for="company_stafftelno" class="col-sm-2 col-form-label"
            >電話番号</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_stafftelno"
              name="company_stafftelno"
              v-model="form.stafftelno"
              pattern="\d{2,4}-?\d{2,4}-?\d{3,4}"
            />
            <div class="invalid-feedback">
              (!) 有効な形式で入力して下さい（数字2～4文字＋ハイフン(-)省略可＋数字2～4文字＋ハイフン(-)省略可＋数字3～4文字）
            </div>
          </div>
          <label for="company_staffmail" class="col-sm-2 col-form-label"
            >メールアドレス</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_staffmail"
              name="company_staffmail"
              v-model="form.staffmail"
              aria-describedby="emailHelp"
              pattern="[\w\-._]+@[\w\-._]+\.[A-Za-z]+"
              maxlength="256"
            />
            <div class="invalid-feedback">
              (!) 正しいメールアドレスの形式で入力して下さい
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="company_note" class="col-sm-2 col-form-label"
            >備考</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              id="company_note"
              name="company_note"
              v-model="form.note"
              pattern="^[^ -~｡-ﾟ]+$"
              maxlength="100"
            />
            <div class="invalid-feedback">
              (!) 有効な形式で入力して下さい（全角文字のみ、100文字以下）
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="company_validateddate" class="col-sm-2 col-form-label"
            >有効期限</label
          >
          <div class="col-sm-10">
            <input
              type="date"
              class="form-control"
              id="company_validateddatee"
              name="company_validateddatee"
              v-model="form.validateddate"
            />
            <div class="invalid-feedback">
              (!) 有効な形式で入力して下さい
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="offset-sm-2 col-sm-10">
            <button type="button" class="btn btn-primary" @click="modalCreate()">登録</button>
          </div>
        </div>
      </form>
    </div>
    
    <!-- Modal：登録 -->
    <div class="modal fade" id="saveModal" tabindex="-1" ref="modal_Insert">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="saveModalLabel">登録確認</h5>
            <button type="button" class="close" @click="modalClose()">✕</button>
          </div>
          <div class="modal-body">登録します。よろしいですか？</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modalClose()">cancel</button>
            <button type="submit" class="btn btn-primary" @click="submitForm()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
  title: "利用企業登録画面",
  name: "company",
  components: {},
  data(){
    return {
      modeModal: null,
      form: {
        certcompanycd: '',
        terminalcnt01: 0,
        terminalcnt02: 0,
        terminalcnt03: 0,
        terminalcnt04: 0,
        terminalcnt05: 0,
        terminalcnt06: 0,
        terminalcnt07: 0,
        terminalcnt08: 0,
        terminalcnt09: 0,
        terminalcnt10: 0,
        terminalcnt11: 0,
        terminalcnt12: 0,
        cardcnt01: 0,
        cardcnt02: 0,
        cardcnt03: 0,
        cardcnt04: 0,
        cardcnt05: 0,
        cardcnt06: 0,
        cardcnt07: 0,
        cardcnt08: 0,
        cardcnt09: 0,
        cardcnt10: 0,
        cardcnt11: 0,
        cardcnt12: 0,
        keycnt01: 0,
        keycnt02: 0,
        keycnt03: 0,
        keycnt04: 0,
        keycnt05: 0,
        keycnt06: 0,
        keycnt07: 0,
        keycnt08: 0,
        keycnt09: 0,
        keycnt10: 0,
        keycnt11: 0,
        keycnt12: 0,
        savedatetime: '',
        validateddate: '',
        companynm: '',
        zipcd: '',
        address: '',
        chief: '',
        chieftelno: '',
        chiefmail: '',
        staff: '',
        stafftelno: '',
        staffmail: '',
        note: ''
      },
      items:   [],      // webAPIから取得したデータ
      results: []       // itemsから抽出したデータ
    }
  },
  created(){
    // ブラウザバックの制御
    history.pushState(null, window.location.title, window.location.href);
    window.addEventListener('popstate', (e) => {
      console.log(e);
      history.go(1);
    });
  },
  methods:{
    modalCreate(){
      let booleanValidity = true;
      var forms = document.querySelectorAll('.needs-validation');
      Array.prototype.slice.call(forms).forEach(function (formsControl) {
        if (!formsControl.checkValidity()) {
          booleanValidity = false;
        }
        formsControl.classList.add('was-validated');
      });
      if (booleanValidity){
        this.modeModal = new Modal(document.getElementById('saveModal'), {keyboard: true})
        this.modeModal.show();
      }
    },
    modalClose: function () {
      let elements = document.getElementsByTagName("div");
      Array.prototype.forEach.call(elements, function (element) {
          element.classList.remove("modal-open");
      });
      this.$delete(this.modeModal, 'modal-backdrop');
      this.modeModal.hide();
    },
    submitForm(){
      const vue = this;   // Important
      // 追加処理
      vue.submitNew();
      vue.modalClose()
    },
    /* 追加処理 */
    submitNew(){
      const url = process.env.VUE_APP_ROOT_API_SVRCERT + '/CertCompanyInsert'
      const vue = this;   // Important
      const option = {responseType: "blob"};
      this.axios.post(url, this.form, option)
        .then((res) => {
          res.data.text().then(str=>{vue.init(str);});
          if (res.status == 500) {
// ★★★：Internal Server Error
            // response.status == 500
            // Internal Server Error
            console.log(res);
            alert('Internal Server Error');
          } else {
            // 画面遷移「登録企業一覧画面」
            this.$router.push({ name: 'menulist' });
          }
        }).catch((e) => {
// ★★★：エラーメッセージ
          console.log(e);
          alert(e.response.status + ':' + e.message);
        });
    },
    init(str){
      this.items = JSON.parse(str).data;      // JSONオブジェクトに変換
      this.reset();
    },
    reset(){
      this.results = this.items;              // resultsにコピー
    }
  }
};
</script>
